import React from 'react';
import Banner from './Props/Banner';
import Banner2 from '../Assets/Banner2.jpg';
import Section from './Props/Section';
import Service from './Props/Service';
import ServiceImg1 from '../Assets/service1.jpg';
import ServiceImg2 from '../Assets/ServiceImg2.jpg';
import ServiceImg3 from '../Assets/ServiceImg3.jpg';
import ServiceImg4 from '../Assets/ServiceImg2.jpg';
import s1 from '../Assets/s1.svg';
import s2 from '../Assets/s2.svg';
import s3 from '../Assets/s3.svg';
import s4 from '../Assets/s4.svg';

function Services() {
    return (
        <div>
            <Banner 
                name="Services" 
                des="Home / Services" 
                img={Banner2} 
            />
            <div className='Section service-gap'>
                <h2>Our Services</h2>
                <Section
                    title="Project Plan"
                    description="At Prestige Dream Decor, we create detailed project plans tailored to your vision. Our team collaborates with you from the initial concept through execution, ensuring every aspect reflects your style and meets your needs."
                    link="/services"
                />
    
                <Section
                    title="Interior Work"
                    description="Our skilled artisans deliver exceptional interior work that enhances the beauty and functionality of your space. We specialize in residential and commercial projects, ensuring every detail is crafted with care and precision."
                    link="/services"
                />
    
                <Section
                    title="Realization"
                    description="We bring your dreams to life through seamless realization of your interior design projects. Our commitment to quality and craftsmanship ensures that your space is transformed into a luxurious haven."
                    link="/services"
                />
            </div>
            <div className='Section service-gap'>
                <Section
                    title="2D/3D Art Work"
                    description="Our 2D and 3D artwork services add a unique touch to your space. Whether it’s stunning visuals or intricate designs, we create artistic solutions that elevate your interiors and reflect your personal style."
                    link="/services"
                />
    
                <Section
                    title="Interior Styling"
                    description="Transform your space with our expert interior styling services. We curate beautiful decor and furnishings that create a harmonious balance between aesthetics and comfort, making your home a true reflection of you."
                    link="/services"
                />
    
                <Section
                    title="Decoration Work"
                    description="Our decoration work combines elegance and creativity, bringing your vision to life. From conceptualizing decor themes to implementing stunning designs, we ensure your space feels inviting and luxurious."
                    link="/services"
                />
            </div>
            <div className='Services-content'>
                <div className='Project-title'>
                    <h2>How We Work</h2>
                    <p>
                        At Prestige Dream Decor, we follow a meticulous process to ensure that every project is executed to perfection. Our commitment to quality and attention to detail sets us apart in the interior design industry.
                    </p>
                </div>
                <div className='work-sr'>
                    <Service
                        className="service-item"
                        img1={ServiceImg1}
                        img2={s1}
                        imgt="01"
                        title="Concept & Details"
                        des="We develop comprehensive concepts and attention to detail that shape the foundation of your project. Our team collaborates with you to ensure your vision is captured and brought to life."
                    />
    
                    <Service
                        className="service-item"
                        img1={ServiceImg2}
                        img2={s2}
                        imgt="02"
                        title="Idea for Work"
                        des="Our creative team generates innovative ideas tailored to your style and preferences. We explore various concepts to find the perfect fit for your space, making sure it's both functional and beautiful."
                    />
    
                    <Service
                        className="service-item"
                        img1={ServiceImg3}
                        img2={s3}
                        imgt="03"
                        title="Design"
                        des="Our design process focuses on crafting spaces that are not only aesthetically pleasing but also functional. We utilize the latest design trends and techniques to create interiors that inspire and elevate your lifestyle."
                    />
    
                    <Service
                        className="service-item"
                        img1={ServiceImg4}
                        img2={s4}
                        imgt="04"
                        title="Perfection"
                        des="At Prestige Dream Decor, we strive for perfection in every project. Our meticulous approach ensures that every detail aligns with your vision, resulting in stunning spaces that exceed expectations."
                    />
                </div>
            </div>
        </div>
    );
}

export default Services;
