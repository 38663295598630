import React from 'react';
import Background from '../Assets/Background.jpg';
import Callicon from '../Assets/Logo.png';
import Section from './Props/Section.js';
import Content from './Props/Content.js';
import About from '../Assets/About.png';
import Project from './Props/Project';
import p1 from '../Assets/p1.jpg';
import p2 from '../Assets/p2.jpg';
import p3 from '../Assets/p3.jpg';
import p4 from '../Assets/p4.jpg';
import bt from '../Assets/bt.svg';
import Counter from './Props/Counter';
import Line from '../Assets/Line.jpg';

function Home() {
    return (
        <div className="Home">
            {/* Banner Section */}
            <div className="Banner">
                <div className="img">
                    <img src={Background} alt="Background of an elegantly designed room" />
                </div>
                <div className="content">
                    <Content
                        title="Interior Design and Home Decor Services"
                        description="Transforming spaces into luxurious and inviting environments with a touch of elegance and style. Discover custom interior design and home decor solutions tailored to your unique vision."
                        name="Get Started"
                    />
                </div>
            </div>

            {/* Sections for Project Plan, Interior Work, Realization */}
            <div className="Section">
                <Section
                    title="Project Plan"
                    description="At Prestige Dream Decor, we create custom plans to turn your vision into reality. Each element is crafted with care to bring elegance and style to your dream space."
                    link="/services"
                />

                <Section
                    title="Interior Work"
                    description="Our expert team transforms spaces with high-quality interior work. Whether it’s a home or commercial project, we deliver stylish, comfortable designs tailored to your needs."
                    link="/services"
                />

                <Section
                    title="Realization"
                    description="From initial ideas to the final reveal, Prestige Dream Decor ensures seamless realization of your interior projects. We turn your ideas into reality, creating luxurious spaces you’ll love."
                    link="/services"
                />
            </div>

            {/* About Section */}
            <div className="About">
                <div className="About-post">
                    <Content
                        title="Designing Stylish, Comfortable Spaces for Luxurious Living"
                        titleClass="about-title-class"
                        description="Prestige Dream Decor offers luxury interior design and home decor services tailored to your unique style. As a top-rated interior design company, we specialize in custom interior designs and modern home decor solutions, combining elegance with comfort. Whether you're looking for affordable luxury interiors or a complete home renovation solution, our interior design experts are here to transform your space."
                        descriptionClass="about-des-class"
                        hideButton={true}
                    />
                    <div className="about-call">
                        <img className="call" src={Callicon} alt="Phone icon" />
                        <div className="about-text">
                            <span>+91 7975709648</span>
                            <p>Call Us Anytime</p>
                        </div>
                    </div>
                    <Content
                        name="Get Free Estimate"
                        descriptionClass="about-des-class"
                        buttonClass="about-bt-class"
                    />
                </div>
                <div className="about-img">
                    <img src={About} alt="About Prestige Dream Decor" />
                </div>
            </div>

            {/* Project Section */}
            <div className="Project-Section">
                <div className="Project-title">
                    <span>Follow Our Projects</span>
                    <p>
                        Discover how Prestige Dream Decor transforms ordinary spaces into stunning, personalized interiors. Our projects showcase the perfect blend of luxury, comfort, and functionality, crafted to meet your unique style. Whether you're looking to redesign your home or create an inviting business space, follow our latest projects for inspiration and expert insights on creating beautiful, livable spaces.
                    </p>
                </div>
                <div className="flex flex-wrap justify-between pros">
                    {[p1, p2, p3, p4].map((projectImage, index) => (
                        <div key={index}>
                            <Project
                                image={projectImage}
                                name="Modern Kitchen"
                                description="Decor / Architecture"
                                buttonText={bt}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Counter Section */}
            <div className="Counter">
                <Counter name="12" description="Years Of Experience" />
                <img className="line" src={Line} alt="Divider line" />
                <Counter name="85" description="Successful Projects" />
                <img className="line" src={Line} alt="Divider line" />
                <Counter name="15" description="Active Projects" />
                <img className="line" src={Line} alt="Divider line" />
                <Counter name="95" description="Happy Customers" />
            </div>

            {/* Footer Section (Add Content as Needed) */}
            <div className="Footer-Home">
                {/* Add Footer Content Here */}
            </div>
        </div>
    );
}

export default Home;
