import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../Assets/Logo.png";
import Fb from "../../Assets/fb.svg";
import Tw from "../../Assets/tw.svg";
import Ln from "../../Assets/ln.svg";
import Inst from "../../Assets/inst.svg";

function Footer() {
    return (
        <div>
            <div className="Footer-Section">
                <div className='Footer-first'>
                    <div className="Logo">
                        <img src={Logo} alt="Prestige Dream Decor Logo" />
                        <h3>Prestige Dream Decor</h3>
                    </div>
                    <div className='footer-des'>
                        <span>
                            We blend elegance and comfort to create luxurious interiors that reflect your unique lifestyle and taste. From modern living rooms to stylish kitchens, Prestige Dream Decor is dedicated to transforming your space.
                        </span>
                    </div>
                    <div className='social'>
                        <a href="https://www.facebook.com/profile.php?id=61557013394157&mibextid=ZbWKwL" title="Follow us on Facebook"><img src={Fb} alt="Facebook" /></a>
                        <a href="https://mail.google.com/mail/u/0/?fs=1&to=prestigedreamdecor@gmail.com&tf=cm" title="Email us"><img src={Tw} alt="Email" /></a>
                        <a href="https://www.linkedin.com/in/prestige-dream-decor-945606317/" title="Connect with us on LinkedIn"><img src={Ln} alt="LinkedIn" /></a>
                        <a href="https://www.instagram.com/prestigedreamdecor/" title="Follow us on Instagram"><img src={Inst} alt="Instagram" /></a>
                    </div>
                </div >
                
                <div className='Footer-Second'>
                    <span>Pages</span>
                    <Link to="/about"><p title="Learn more about Prestige Dream Decor">About Us</p></Link>
                    <Link to="/project"><p title="Explore our recent interior design projects">Our Projects</p></Link>
                    <Link to="/contact"><p title="Contact Prestige Dream Decor">Contact Us</p></Link>
                    <Link to="/services"><p title="View our range of interior design services">Services</p></Link>
                </div>
                
                <div className='Footer-Second'>
                    <span>Services</span>
                    <Link to="/services"><p title="Kitchen design and remodeling">Kitchen</p></Link>
                    <Link to="/services"><p title="Living area design">Living Area</p></Link>
                    <Link to="/services"><p title="Bathroom design and decor">Bathroom</p></Link>
                    <Link to="/services"><p title="Dining hall design and decor">Dining Hall</p></Link>
                    <Link to="/services"><p title="Bedroom design and decor">Bedroom</p></Link>
                </div>
                
                <div className='Footer-Second'>
                    <span>Contact</span><br />
                    <p>1001/52/1 Main Road, Nanjappa - Thindlu Rd, Doddabommasandra, Vidyaranyapura, Bengaluru, Karnataka 560097</p>
                    <p><a href="https://prestigedreamdecor.in" title="Visit Prestige Dream Decor's website">prestigedreamdecor.in</a></p>
                    <p><a href="tel:+917975709648" title="Call Prestige Dream Decor">+91 7975709648</a></p>
                </div>
            </div >
            <div>
                <span className='copyright'>Copyright © {new Date().getFullYear()} Prestige Dream Decor. All rights reserved.</span>
            </div>
        </div >
    );
}

export default Footer;
