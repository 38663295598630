import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from './Props/Banner';
import Content from './Props/Content';
import Banner6 from '../Assets/Banner6.jpg';
import Pattern from '../Assets/Pattern.jpg';
import Pattern1 from '../Assets/Pattern1.jpg';
import Ai1 from '../Assets/bsi1.jpg';
import Ai2 from '../Assets/bsi2.jpg';

function About() {
    return (
        <div>
            {/* SEO Metadata */}
            <Helmet>
                <title>About Us - Prestige Dream Decor | Exceptional Interior Design Services</title>
                <meta name="description" content="Established in [Establishment Year], Prestige Dream Decor specializes in luxury interior design services for both residential and commercial spaces. We create sophisticated, timeless interiors tailored to each client's vision." />
                <meta name="keywords" content="Prestige Dream Decor, luxury interior design, custom interior solutions, established in [Establishment Year], residential decor, commercial design, elegant interiors" />
                <link rel="canonical" href="https://www.prestigedreamdecor.com/about" />
            </Helmet>

            {/* Page Banner */}
            <Banner name="About Us" des="Home / About Us" img={Banner6} />

            {/* Inspirational Quote Section */}
            <div className='About-s-1 flex' style={{ position: 'relative' }}>
                <img src={Pattern} alt="decorative pattern" style={{ marginRight: '600px' }} />
                <div className='flex flex-col items-center gap-y-6 About-h justify-center' style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <span>“</span>
                    <h3>“Great design is born of the details.”</h3>
                    <p>- Prestige Dream Decor</p>
                </div>
                <img src={Pattern1} alt="decorative pattern1" />
            </div>

            {/* About Prestige Dream Decor Section */}
            <div className='About-s-2 gap-y-24'>
                <div className='flex gap-x-16'>
                    <Content
                        title="Our Story"
                        description="Founded in 2008, Prestige Dream Decor has built a reputation as a leader in luxury interior design. Our mission is to craft refined spaces that blend elegance with functionality, whether for homes or businesses. Each project is a reflection of our dedication to quality, aesthetics, and client satisfaction."
                        descriptionClass="a-d-class"
                        name="What Sets Us Apart"
                        buttonClass="a-b-class"
                    />
                    <img src={Ai1} alt="Luxury interior design concept" />
                </div>
                <div className='flex gap-x-16'>
                    <img src={Ai2} alt="Prestige Dream Decor portfolio example" />
                    <Content
                        title="Our Portfolio"
                        description="With a portfolio that showcases our expertise, Prestige Dream Decor is synonymous with quality and craftsmanship. We excel in bespoke designs that highlight each room's potential, whether transforming a private residence or enhancing a commercial space. Our team is committed to achieving excellence, creating interiors that capture the essence of our clients' aspirations."
                        descriptionClass="a-d-class"
                        name="Explore Our Work"
                        buttonClass="a-b-class"
                    />
                </div>
            </div>

            {/* Contact Call-to-Action */}
            <div className='About-s-4 flex flex-col justify-center items-center'>
                <span>Ready to Transform Your Space?</span>
                <p className="text-center mt-4 max-w-xl">
                    Embark on a journey of elegance with Prestige Dream Decor. Our design experts are here to transform your environment into a luxurious space that reflects your personal style and needs. Contact us to explore how we can bring your vision to life with our bespoke design services.
                </p>
            </div>
        </div>
    );
}

export default About;
